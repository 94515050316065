@font-face {
  font-family: 'Proxima Nova Soft';
  src:
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff2') format('woff2'),
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.woff') format('woff'),
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftRegularWebfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Proxima Nova Soft';
  src:
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff2') format('woff2'),
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.woff') format('woff'),
    url('https://cdn.inewi.pl/fonts/ProximaNovaSoftBoldWebfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

.page-wrapper {
  background: white;
  font-family: 'Proxima Nova Soft', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  padding-top: 40px;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  margin-top: 30px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 470px;
  max-width: 100%;
  align-items: center;
  color: #253353;
}

.content__header {
  margin: 0;
  padding: 0;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
}

.content__paragraph {
  margin: 0;
  padding: 0;
  font-size: 20px;
  text-align: center;
}

.content__buttons-wrapper {
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
  justify-content: center;
}

.button {
  display: inline-flex;
  min-width: auto;
  appearance: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;
  font-family: inherit;
  line-height: inherit;
  text-decoration: none;
  font-size: inherit;
  border: 0;
  outline: none;
  font-weight: bold;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.button:active {
  transform: scale(0.975);
}

.button--primary {
  color: #f9fafc;
}

.button--primary--inewi {
  background: linear-gradient(270deg, #66b5f9 0%, #4a8ed6 100%);
}

.button--primary--inewi:hover {
  background: linear-gradient(270deg, #63cbea 0%, #34aadc 100%);
}

.button--primary--inewi:active {
  background: linear-gradient(270deg, #2686bd 0%, #1a659e 100%);
}

.button--primary--unrubble {
  background: linear-gradient(270deg, #9e58f5 0%, #9346f4 100%);
}

.button--primary--unrubble:hover {
  background: linear-gradient(270deg, #b37df7 0%, #9e58f5 100%);
}

.button--primary--unrubble:active {
  background: linear-gradient(270deg, #9346f4 0%, #710eed 100%);
}

.button--default {
  background: #eaecf1;
}

.button--default:hover {
  background: #cad0db;
}

.button--default:active {
  background: #aab3c5;
}
